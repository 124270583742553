@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Outfit", system-ui, sans-serif;
    font-weight: 300;
    color: #151515;
  }
}